import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { imageUrlFor } from '../lib/image-url';
import { buildImageObj } from '../lib/helpers';
import { DefaultSeoQueryQuery } from '../../graphql-types';

interface Props {
  title: string;
  description?: string;
  lang?: string;
  meta?: any;
  keywords?: string[];
  image?: any;
}

const SEO: React.FC<Props> = ({
  title,
  description,
  lang = 'en',
  meta = [],
  keywords = [],
  image,
}) => {
  const { site } = useStaticQuery<DefaultSeoQueryQuery>(detailsQuery) || {};

  const metaDescription = description || site?.description || '';
  const siteTitle = site?.title || '';
  const siteAuthor = site?.author?.name || '';
  const metaImage = image?.asset
    ? imageUrlFor(buildImageObj(image)).width(1200).url()
    : '';

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
      // @ts-ignore
      meta={[
        {
          name: 'description',

          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: siteAuthor,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ]
        .concat(
          keywords && keywords.length > 0
            ? {
                name: 'keywords',
                content: keywords.join(', '),
              }
            : []
        )
        .concat(meta)}
    />
  );
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
      author {
        name
      }
    }
  }
`;
